.kpi-tooltip {
  font-size: 14px;
  background-color: #616161;
  color: var(--primary-white);
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1000;
  display: block;
  opacity: 0;
  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  transition: opacity 300ms;
}

.kpi-tooltip-sizing {
  max-width: 200px;
  padding: 5px 8px;
}

.kpi-tooltip-show {
  opacity: 1;
}

.kpi-tooltip::after {
  content: "";
  position: absolute;
  border-style: solid;
}

.kpi-tooltip-top::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: #616161 transparent transparent transparent;
}

.kpi-tooltip-bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent #616161 transparent;
}

.kpi-tooltip-left::after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent #616161;
}

.kpi-tooltip-right::after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent #616161 transparent transparent;
}