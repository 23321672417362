@import "~@angular/cdk/overlay-prebuilt.css";
// @import "./work-sans.scss";
@import "./tooltip.scss";
@import "./variable.scss";

body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-brown);
  background: var(--primary-white);
  * {
    box-sizing: border-box;
  }
  img {
    max-width: 100%;
  }
  .landlord {
    background-color: var(--primary-color);
  }
  .tenant {
    background-color: var(--warning-color);
  }
  .agent {
    background-color: var(--manager-color);
  }
  .error-message {
    font-size: 12px;
    color: var(--error-color);
    font-weight: 400;
    position: absolute;
    top: 30px;
    margin-top: 5px;
  }
  .user-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--primary-purple);
}
a {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary-black);
  cursor: pointer;
}

.radio-conversation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.radio-conversation > .container-radio {
  margin-bottom: 10px;
  margin-right: 5px;
  white-space: nowrap;
}

.save-changes {
  border: none;
  margin-top: 45px;
  height: 36px;
  width: 100%;
  border-radius: 2px;
  background-color: var(--button-secondary-color);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  color: var(--primary-white);
  font-weight: 500;
  line-height: 17px;
  text-align: center;

  &.disabled {
    opacity: 0.65;
  }
}

.wrapper-background {
  min-height: 100%;
  background-color: var(--light-color);
}

.pac-container {
  z-index: 9999;
}

.top-section {
  display: flex;
  align-items: center;
  font-weight: 500;
  background-color: var(--primary-white);
  padding: 14px 23px;
  border-bottom: 6px solid rgb(245, 245, 245);

  & .center-block {
    justify-self: center;
    text-align: center;

    & :last-child {
      font-weight: 400;
      font-size: 12px;
    }

    & :first-child {
      color: var(--primary-dark-gray);
      font-size: 16px;
    }
  }

  & .cancel-btn,
  & .edit {
    color: var(--primary-color);
    align-self: center;
    cursor: pointer;
    font-size: var(--base-font-size);
  }
}

.form-wrap {
  display: flex;
  justify-content: center;
  padding-top: 74px;
}

.save-btn {
  width: 100%;
  border-radius: 2px;
  background-color: var(--button-secondary-color);
  color: var(--primary-white);

  &:disabled {
    opacity: 0.4;
  }
}

.new-btn {
  display: flex;
  align-items: baseline;
  font-weight: 600;
  border: none;
  background-color: var(--white-primary);
  color: var(--button-color);
  letter-spacing: 0.5px;

  & img {
    margin-right: 5px;
  }

  &:hover {
    opacity: 1;
  }
}

// checkbox styles
.checkbox-container {
  width: 100%;
  font-weight: 400;
  font-weight: 500;
  display: block;
  position: relative;
  padding-right: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  border: 2px solid var(--secondary-dark-gray);
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: var(--radio-color);
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 1px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* The container radio button big*/

.container-radio {
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  position: relative;
  padding: 10px 0;
}

.container-radio input {
  display: none;
}

.checkmark-radio {
  background: var(--primary-white);
  border: 1px solid var(--primary-dark-cyan) !important;
  text-transform: none;
  border-radius: 100px;
  text-align: center;
  color: var(--primary-dark-cyan);
  padding: 10px 15px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;

  &:hover,
  &:focus {
    opacity: 1;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
}

.container-radio input:checked ~ .checkmark-radio {
  background: var(--primary-dark-cyan);
  color: var(--primary-white);
}

/* The container radio button small*/
.container-radio-small {
  display: inline-block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radio-small input {
  display: none;
}

/* Create a custom radio button */
.checkmark-radio-small {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid var(--border-color-green);
  border-radius: 50%;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark-radio-small:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio-small input:checked ~ .checkmark-radio-small:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio-small .checkmark-radio-small:after {
  width: 10px;
  height: 10px;
  top: 3px;
  left: 3px;
  border-radius: 50%;
  background: var(--border-color-green);
}

//hide "add property" in property component
//hide "add user" when click in "people" in control panel component
//hide "edit" in show-person component
//hide "lease summary" in menu component 
.hide{
  display: none;
}
