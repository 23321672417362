@font-face {
	font-family: 'work_sansblack';
	src: url('../fonts/work-sans/worksans-black-webfont.woff2') format('woff2'), url('../fonts/work-sans/worksans-black-webfont.woff') format('woff');
}
@font-face {
	font-family: 'work_sansbold';
	src: url('../fonts/work-sans/worksans-bold-webfont.woff2') format('woff2'), url('../fonts/work-sans/worksans-bold-webfont.woff') format('woff');
}
@font-face {
	font-family: 'work_sansextrabold';
	src: url('../fonts/work-sans/worksans-extrabold-webfont.woff2') format('woff2'), url('../fonts/work-sans/worksans-extrabold-webfont.woff') format('woff');
}
@font-face {
	font-family: 'work_sansextralight';
	src: url('../fonts/work-sans/worksans-extralight-webfont.woff2') format('woff2'), url('../fonts/work-sans/worksans-extralight-webfont.woff') format('woff');
}
@font-face {
	font-family: 'work_sanslight';
	src: url('../fonts/work-sans/worksans-light-webfont.woff2') format('woff2'), url('../fonts/work-sans/worksans-light-webfont.woff') format('woff');
}
@font-face {
	font-family: 'work_sansmedium';
	src: url('../fonts/work-sans/worksans-medium-webfont.woff2') format('woff2'), url('../fonts/work-sans/worksans-medium-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'work_sansregular';
	src: url('../fonts/work-sans/worksans-regular-webfont.woff2') format('woff2'), url('../fonts/work-sans/worksans-regular-webfont.woff') format('woff');
}
@font-face {
	font-family: 'work_sanssemibold';
	src: url('../fonts/work-sans/worksans-semibold-webfont.woff2') format('woff2'), url('../fonts/work-sans/worksans-semibold-webfont.woff') format('woff');
}
@font-face {
	font-family: 'work_sansthin';
	src: url('../fonts/work-sans/worksans-thin-webfont.woff2') format('woff2'), url('../fonts/work-sans/worksans-thin-webfont.woff') format('woff');
}
body {
	padding-right: 0px !important
}
.modal {
	overflow: hidden;
}
body {
	/*background: #f2f2f2;*/
	background: #f5f5f5;
	margin: 0;
	-webkit-text-size-adjust: 100%;
	font-family: 'work_sansregular';
}
h1, h2, h3, h4, h5, h6 {
	margin: 10px 0;
}
h1 {
	line-height: 43px;
}
h2 {
	line-height: 35px;
}
h3 {
	line-height: 30px;
}
h4 {
	line-height: 22px;
}
h3 small, h4 small, h5 small {
	color: #444;
}
h1, h2, h3, h4, h5, h6 {
	font-family: 'work_sansregular';
	color: #505458;
	font-weight: 500;
}
* {
	outline: none !important;
}
a:hover, a:active, a:focus {
	outline: 0;
	text-decoration: none;
}
:focus {
	outline: none;
}
::-moz-focus-inner {
 border: 0;
}
::selection {
	background: rgba(49, 126, 235, 0.5);
	color: #ffffff;
}
::-moz-selection {
 background: rgba(49, 126, 235, 0.5);
 color: #ffffff;
}
.container {
	width: auto;
}
.container-alt {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.footer {
	color: #58666e;
	position: absolute;
	width: 100%;
	bottom: 0px;
	padding: 20px 30px;
	background-color: #f9f9f9;
	border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.page {
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
.page-title {
	margin-bottom: 24px;
}
.breadcrumb {
	background-color: transparent;
	margin-top: 5px;
	margin-bottom: 15px;
}
/******** 2. Bootstrap RESET ********/
/* ROW/Col */
.row {
	margin-right: -15px;
	margin-left: -15px;
}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
	padding-left: 10px;
	padding-right: 10px;
}
/* 2.0 Dropdown */
.dropdown-menu {
	box-shadow: none;
	padding: 4px 0;
	-webkit-animation: dropdownOpen 0.3s ease-out;
	-o-animation: dropdownOpen 0.3s ease-out;
	animation: dropdownOpen 0.3s ease-out;
	border: 0;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
}
.dropdown-menu > li > a {
	padding: 6px 20px;
}
@-webkit-keyframes dropdownOpen {
 0% {
 -webkit-transform: scale(0);
 -ms-transform: scale(0);
 -o-transform: scale(0);
 transform: scale(0);
 opacity: 0;
}
 100% {
 -webkit-transform: scale(1);
 -ms-transform: scale(1);
 -o-transform: scale(1);
 transform: scale(1);
 opacity: 1;
}
}
@keyframes dropdownOpen {
 0% {
 -webkit-transform: scale(0);
 -ms-transform: scale(0);
 -o-transform: scale(0);
 transform: scale(0);
 opacity: 0;
}
 100% {
 -webkit-transform: scale(1);
 -ms-transform: scale(1);
 -o-transform: scale(1);
 transform: scale(1);
 opacity: 1;
}
}
/* 2.1 panels*/
.panel {
	border: none;
	border-radius: 0px;
	margin-bottom: 20px;
	-webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
}
.panel .panel-body {
	padding: 20px;
}
.panel .panel-body p {
	margin: 0px;
}
.panel .panel-body p+p {
	margin-top: 15px;
}
.panel-heading {
	border-radius: 0;
	border: none !important;
	padding: 10px 20px;
}
.panel-default>.panel-heading {
	color: #797979;
	background-color: var(--secondary-white);
	border-bottom: none;
}
.panel-title {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 600;
	margin-bottom: 0;
	margin-top: 0;
}
.panel-footer {
	background: var(--secondary-white);
	border-top: 0px;
}
.panel-color .panel-title {
	color: #ffffff;
}
.panel-primary>.panel-heading {
	background-color: #1e88e5;
}
.panel-success>.panel-heading {
	background-color: #33b86c;
}
.panel-info>.panel-heading {
	background-color: #29b6f6;
}
.panel-warning>.panel-heading {
	background-color: #ffd740;
}
.panel-danger>.panel-heading {
	background-color: #ef5350;
}
.panel-purple>.panel-heading {
	background-color: #7e57c2;
}
.panel-pink>.panel-heading {
	background-color: #ec407a;
}
.panel-inverse>.panel-heading {
	background-color: #212121;
}
.panel-border {
	border-radius: 3px;
}
.panel-border .panel-heading {
	background-color: #ffffff;
	border-top: 3px solid #ccc !important;
	border-radius: 3px;
	padding: 10px 20px 0px;
}
.panel-border .panel-body {
	padding: 15px 20px 20px 20px;
}
.panel-border.panel-primary .panel-heading {
	border-color: #1e88e5 !important;
	color: #1e88e5 !important;
}
.panel-border.panel-success .panel-heading {
	border-color: #33b86c !important;
	color: #33b86c !important;
}
.panel-border.panel-info .panel-heading {
	border-color: #29b6f6 !important;
	color: #29b6f6 !important;
}
.panel-border.panel-warning .panel-heading {
	border-color: #ffd740 !important;
	color: #ffd740 !important;
}
.panel-border.panel-danger .panel-heading {
	border-color: #ef5350 !important;
	color: #ef5350 !important;
}
.panel-border.panel-purple .panel-heading {
	border-color: #7e57c2 !important;
	color: #7e57c2 !important;
}
.panel-border.panel-pink .panel-heading {
	border-color: #ec407a !important;
	color: #ec407a !important;
}
.panel-border.panel-inverse .panel-heading {
	border-color: #212121 !important;
	color: #212121 !important;
}
.panel-fill {
	border-radius: 3px;
}
.panel-fill .panel-heading {
	background-color: transparent;
	color: #ffffff;
	border-bottom: 1px solid rgba(255,255,255,0.5) !important;
}
.panel-fill .panel-body {
	color: rgba(255,255,255,.85);
}
.panel-fill.panel-default .panel-body {
	color: #666;
}
.panel-fill.panel-default .panel-heading {
	background-color: transparent;
	color: #333;
	border-bottom: 1px solid rgba(0,0,0,0.1) !important;
}
.panel-fill.panel-primary {
	background-color: #489ce7;
}
.panel-fill.panel-success {
	background-color: #58c386;
}
.panel-fill.panel-info {
	background-color: #50c1f4;
}
.panel-fill.panel-warning {
	background-color: #fcdc63;
}
.panel-fill.panel-danger {
	background-color: #ef7270;
}
.panel-fill.panel-purple {
	background-color: #9475cb;
}
.panel-fill.panel-pink {
	background-color: #ec6391;
}
.panel-fill.panel-inverse {
	background-color: #4a4a4a;
}
/* 2.2 Buttons */
.btn {
	border-radius: 2px;
	font-family: 'work_sansregular';
	letter-spacing: 0.2px;
	opacity: 0.93;
	-webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
}
.btn:hover, .btn:focus {
	opacity: 1;
	-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	-moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger, .btn-inverse, .btn-purple, .btn-pink {
	color: #FFFFFF !important;
}
.btn-default:hover, .btn-default:focus, .btn-default:active {
	background-color: #f9f9f9;
}
.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary.focus, .btn-primary:active, .btn-primary:focus, .btn-primary:hover, .open>.dropdown-toggle.btn-primary {
	background-color: #38DBD0;
	border: 1px solid #38DBD0;
}
.btn-success, .btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success.focus, .btn-success:active, .btn-success:focus, .btn-success:hover, .open>.dropdown-toggle.btn-success {
	background-color: #33b86c;
	border: 1px solid #33b86c;
}
.btn-info, .btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info.focus, .btn-info:active, .btn-info:focus, .btn-info:hover, .open>.dropdown-toggle.btn-info {
	background-color: #4F55E2;
	border: 1px solid #4F55E2;
}
.btn-warning, .btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning.focus, .btn-warning:active, .btn-warning:focus, .btn-warning:hover, .open>.dropdown-toggle.btn-warning {
	background-color: #ffd740;
	border: 1px solid #ffd740;
	color: #333 !important;
}
.btn-danger, .btn-danger:active, .btn-danger:focus, .btn-danger:hover, .btn-danger.active, .btn-danger.focus, .btn-danger:active, .btn-danger:focus, .btn-danger:hover, .open>.dropdown-toggle.btn-danger {
	background-color: #E11E02;
	border: 1px solid #E11E02;
}
.btn-inverse, .btn-inverse:hover, .btn-inverse:focus, .btn-inverse:active, .btn-inverse.active, .btn-inverse.focus, .btn-inverse:active, .btn-inverse:focus, .btn-inverse:hover, .open>.dropdown-toggle.btn-inverse {
	background-color: #212121;
	border: 1px solid #212121;
	color: #FFFFFF;
}
.btn-purple, .btn-purple:hover, .btn-purple:focus, .btn-purple:active {
	background-color: #7e57c2;
	border: 1px solid #7e57c2;
	color: #FFFFFF;
}
.btn-pink, .btn-pink:hover, .btn-pink:focus, .btn-pink:active {
	background-color: #ec407a;
	border: 1px solid #ec407a;
	color: #FFFFFF;
}
.btn-custom {
	background: transparent;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	border-width: 1px;
	-webkit-transition: all 400ms ease-in-out;
	-moz-transition: all 400ms ease-in-out;
	-o-transition: all 400ms ease-in-out;
	transition: all 400ms ease-in-out;
}
.btn-custom.btn-default:hover, .btn-custom.btn-default:active, .btn-custom.btn-default:focus {
	color: #333 !important;
}
.btn-custom.btn-primary {
	color: #1e88e5 !important;
}
.btn-custom.btn-success {
	color: #33b86c !important;
}
.btn-custom.btn-info {
	color: #29b6f6 !important;
}
.btn-custom.btn-warning {
	color: #ffd740 !important;
}
.btn-custom.btn-danger {
	color: #ef5350 !important;
}
.btn-custom.btn-inverse {
	color: #212121 !important;
}
.btn-custom.btn-purple {
	color: #7e57c2 !important;
}
.btn-custom.btn-pink {
	color: #ec407a !important;
}
.btn-custom:hover, .btn-custom:focus {
	color: #FFFFFF !important;
}
.btn-rounded {
	border-radius: 2em;
	padding: 6px 20px;
	font-size: 18px;
	line-height: 21px;
	font-weight: 500;
	height: 40px;
}
/* 2.3 Choose-button */
.fileUpload {
	position: relative;
	overflow: hidden;
}
.fileUpload input.upload {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	font-size: 20px;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity=0);
}
/* 2.4 Background Colors */
.bg-primary {
	background-color: #1e88e5;
}
.bg-success {
	background-color: #33b86c;
}
.bg-info {
	background-color: #29b6f6;
}
.bg-warning {
	background-color: #ffd740;
}
.bg-danger {
	background-color: #ef5350;
}
.bg-muted {
	background-color: #d0d0d0;
}
.bg-inverse {
	background-color: #212121;
}
.bg-purple {
	background-color: #7e57c2;
}
.bg-pink {
	background-color: #ec407a;
}
.white-bg {
	background-color: #ffffff;
}
/* 2.5 text color*/
.text-white {
	color: #ffffff;
}
.text-danger {
	color: #ef5350;
}
.text-muted {
	color: #98a6ad;
}
.text-primary {
	color: #1e88e5;
}
.text-warning {
	color: #ffd740;
}
.text-success {
	color: #33b86c;
}
.text-info {
	color: #29b6f6;
}
.text-inverse {
	color: #212121;
}
.text-pink {
	color: #ec407a;
}
.text-purple {
	color: #7e57c2;
}
.text-dark {
	color: #797979 !important;
}
/*2.6 text input*/
.form-control {
	background-color: var(--secondary-white);
	color: rgba(0,0,0,0.6);
	font-size: 14px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	-webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
	-moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
	box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
	border: 1px solid #eee;
	box-shadow: none;
}
.form-control:focus {
	border: 1px solid #e0e0e0;
	background: #FFF;
	box-shadow: none;
}
/* 2.7 Labels */
.label-primary {
	background-color: #1e88e5;
}
.label-success {
	background-color: #33b86c;
}
.label-info {
	background-color: #29b6f6;
}
.label-warning {
	background-color: #ffd740;
}
.label-danger {
	background-color: #ef5350;
}
.label-purple {
	background-color: #7e57c2;
}
.label-pink {
	background-color: #ec407a;
}
.label-inverse {
	background-color: #212121;
}
/* 2.8 Badge */
.badge {
	text-transform: uppercase;
	font-weight: normal;
	padding: 3px 5px;
	font-size: 12px;
	margin-top: 1px;
}
.badge-xs {
	font-size: 9px;
}
.badge-xs, .badge-sm {
	-webkit-transform: translate(0, -2px);
	-ms-transform: translate(0, -2px);
	-o-transform: translate(0, -2px);
	transform: translate(0, -2px);
}
.badge-primary {
	background-color: #1e88e5;
}
.badge-success {
	background-color: #33b86c;
}
.badge-info {
	background-color: #29b6f6;
}
.badge-warning {
	background-color: #ffd740;
}
.badge-danger {
	background-color: #ff2a68;
}
.badge-purple {
	background-color: #7e57c2;
}
.badge-pink {
	background-color: #ec407a;
}
.badge-inverse {
	background-color: #212121;
}
/* 2.9 Pagination */
.pagination>li>a, .pagination>li>span {
	color: #373e4a;
	background-color: #fff;
	border: 1px solid #ddd;
	font-size: 15px;
}
.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
	background-color: #317eeb;
	border-color: #317eeb;
}
/* 2.10 Tabs-Accordions */
.tabs {
	position: relative;
	background-color: #FFF;
	margin: 0 auto;
	width: 100%;
	white-space: nowrap;
	padding: 0px;
}
.tabs li.tab {
	display: block;
	float: left;
	text-align: center;
	background-color: #fff;
	margin: 0;
}
.tabs li.tab a {
	color: #ee6e73;
	display: block;
	text-decoration: none;
	width: 100%;
	height: 100%;
	-webkit-transition: color 0.28s ease;
	-moz-transition: color 0.28s ease;
	-o-transition: color 0.28s ease;
	-ms-transition: color 0.28s ease;
	transition: color 0.28s ease;
	color: #9398a0;
}
.tabs li.tab a.active {
	color: #317eeb !important;
}
.tabs .indicator {
	position: absolute;
	bottom: 0;
	height: 2px;
	background-color: #317eeb;
	will-change: left, right;
}
.tabs-top .indicator {
	top: 0;
}
.nav.nav-tabs+.tab-content, .tabs-vertical-env .tab-content {
	padding: 15px;
	margin-bottom: 30px;
}
.nav.nav-tabs>li.active>a, .tabs-vertical-env .nav.tabs-vertical li.active>a {
	border: 0;
	border-bottom: 2px solid #38DBD0;
	color: #38DBD0!important;
}
.nav.nav-tabs>li>a, .nav.tabs-vertical>li>a {
	border: none;
	padding-left: 15px;
	padding-right: 15px;
	cursor: pointer;
	border-radius: 0;
	line-height: 20px;
	background-color: transparent;
	font-weight: 500 !important;
	text-transform: uppercase;
	opacity: 0.7;
	color: var(--tab-color) !important;
	font-size: 13px !important;
	letter-spacing: 0.46px;
	text-align: center;
	font-family: 'work_sansmedium';
}
.nav.nav-tabs>li>a:hover, .nav.tabs-vertical>li>a:hover {
	color: #30DBD0 !important;
}
.tab-content {
	color: #777;
}
.nav.nav-tabs>li:last-of-type a {
	margin-right: 0px;
}
.nav.nav-tabs {
	border-bottom: 1px solid #eee;
}
.navtab-bg {
	background-color: #fff;
}
.nav-tabs.nav-justified>.active>a, .nav-tabs.nav-justified>.active>a:hover, .nav-tabs.nav-justified>.active>a:focus, .tabs-vertical-env .nav.tabs-vertical li.active>a {
	border: none;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover, .tabs-vertical>li.active>a, .tabs-vertical>li.active>a:focus, .tabs-vertical>li.active>a:hover {
	color: #293b5a !important;
}
img.user-inset {
	border: 1px solid #fff;
	height: 32px;
	width: 32px;
	border-radius: 50%;
}
.navbar-nav {
	width: 100%;
	text-align: center;
}
.navbar-nav > li {
	float: none;
	display: inline-block;
}
.tabs-vertical-env {
	margin-bottom: 30px;
	background-color: #eee;
}
.tabs-vertical-env .nav.tabs-vertical {
	min-width: 120px;
	width: 150px;
}
.tabs-vertical-env .nav.tabs-vertical, .tabs-vertical-env .tab-content {
	display: table-cell;
	vertical-align: top;
}
.panel-group .panel .panel-heading a[data-toggle=collapse].collapsed:before, .panel-group .panel .panel-heading .accordion-toggle.collapsed:before {
	content: '\f067';
}
.panel-group .panel .panel-heading a[data-toggle=collapse]:before, .panel-group .panel .panel-heading .accordion-toggle:before {
	float: right;
	display: block;
	content: '\f068';
	font-family: 'FontAwesome';
	font-size: 14px;
	width: 25px;
	text-align: right;
}
.panel-group .panel .panel-heading a[data-toggle=collapse], .panel-group .panel .panel-heading .accordion-toggle {
	display: block;
}
.tabs-vertical-env .nav.tabs-vertical li>a {
	text-align: center;
	white-space: nowrap;
	color: #333;
}
.panel-group .panel .panel-heading+.panel-collapse .panel-body {
	border-top: none;
}
.panel-group .panel-heading {
	padding: 12px 26px;
}
.panel-group.panel-group-joined .panel+.panel, .panel-group-joined .panel-group .panel+.panel {
	margin-top: 0;
	border-top: 1px solid #eee;
}
/* 2.11 Progress Bars */
.progress {
	overflow: hidden;
	margin-bottom: 18px;
	background-color: #f5f5f5;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	height: 10px;
}
.progress-bar {
	font-size: 8px;
	line-height: 12px;
	font-weight: 600;
	box-shadow: none;
}
.progress.progress-sm {
	height: 5px !important;
}
.progress.progress-md {
	height: 15px !important;
}
.progress.progress-lg {
	height: 20px !important;
}
.progress.progress-sm .progress-bar {
	font-size: 8px;
	line-height: 5px;
}
.progress.progress-md .progress-bar {
	font-size: 10.8px;
	line-height: 14.4px;
}
.progress.progress-lg .progress-bar {
	font-size: 12px;
	line-height: 20px;
}
.progress-bar-primary {
	background-color: #1e88e5;
}
.progress-bar-success {
	background-color: #33b86c;
}
.progress-bar-info {
	background-color: #29b6f6;
}
.progress-bar-warning {
	background-color: #ffd740;
}
.progress-bar-danger {
	background-color: #ef5350;
}
.progress-bar-inverse {
	background-color: #212121;
}
.progress-bar-purple {
	background-color: #7e57c2;
}
.progress-bar-pink {
	background-color: #ec407a;
}
.progress-animated {
	-webkit-transition: 5s all;
	-webkit-animation-duration: 5s;
	-webkit-animation-name: animationProgress;
	transition: 5s all;
	animation-duration: 5s;
	animation-name: animationProgress;
}
@-webkit-keyframes animationProgress {
 from {
 width:0;
}
}
@keyframes animationProgress {
 from {
 width:0;
}
}
/* 2.12 Modals */
.modal .modal-dialog .modal-content {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	border-color: #DDDDDD;
	padding: 0px;
	border-radius: 2px;
}
.modal .modal-dialog .modal-content .modal-header {
	margin: 0;
	padding: 0;
	border-bottom-width: 1px;
	padding-bottom: 15px;
	padding-top: 20px;
	padding-left: 27px;
	padding-right: 25px;
}
.modal .modal-dialog .modal-content .modal-body {
	padding: 20px 35px;
}
.modal .modal-dialog .modal-content .modal-footer {
	padding: 0;
	padding-top: 15px;
}
.modal-full {
	width: 98%;
}
/* Modal-use */
.modal-content .nav.nav-tabs+.tab-content, .tabs-vertical-env .tab-content, .modal-content .panel-group {
	margin-bottom: 0px;
}
.modal-content .panel {
	border-top: none;
}
.table>thead>tr>td.middle-align, .table>tbody>tr>td.middle-align, .table>tfood>tr>td.middle-align, .table>thead>tr>th.middle-align, .table>tbody>tr>th.middle-align, .table>tfood>tr>th.middle-align {
	vertical-align: middle;
}
.legendLabel {
	padding-left: 10px !important;
}
/* 2.13 Alerts */
.alert-success {
	color: #33b86c;
	background-color: #ccedda;
	border-color: #7ad19f;
}
.alert-success .alert-link {
	color: #268a51;
}
/* 2.14 list group*/
.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
	background-color: #ddd;
	border-color: #ddd;
	color: #444;
	z-index: 2;
}
.list-group-item, .list-group-item:first-child, .list-group-item:last-child {
	border-radius: 0px;
	padding: 12px 20px;
}
.list-group-item-heading {
	font-weight: 300;
}
.list-group-item.active>.badge, .nav-pills>.active>a>.badge {
	color: #317eeb;
}
.list-group-item.active .list-group-item-text, .list-group-item.active:focus .list-group-item-text, .list-group-item.active:hover .list-group-item-text {
	color: #317eeb;
}
/* 2.15 Other Inputs */
.has-success .form-control {
	border-color: #33b86c;
	box-shadow: none !important;
}
.has-warning .form-control {
	border-color: #ffd740;
	box-shadow: none !important;
}
.has-error .form-control {
	border-color: #ef5350;
	box-shadow: none !important;
}
.input-group-addon {
	border: 1px solid #eee;
	border-radius: 2px;
}
/***************** 3.Portlets ****************/
/***************** 4.TOPBAR was moved to header.component.scss ****************/

/* SEARCHBAR */
.search-bar {
	background-color: transparent !important;
	border: none !important;
	color: #fff;
	box-shadow: none !important;
	height: 50px;
	font-size: 16px;
	margin-top: 3px;
}
.btn-search {
	display: none;
}
input.search-bar::-webkit-input-placeholder {
 color: rgba(255,255,255,0.6);
}
input.search-bar:-moz-placeholder {
 color: rgba(255,255,255,0.6);
}
input.search-bar::-moz-placeholder {
 color: rgba(255,255,255,0.6);
}
input.search-bar:-ms-input-placeholder {
 color: rgba(255,255,255,0.6);
}
.logo {
	font-size: 18px;
	letter-spacing: .05em;
	color: #fff !important;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 60px;
}
.logo i {
	font-size: 30px;
	margin-right: 5px;
	color: #ee6e73;
}
.navbar-nav {
	margin: 0px;
}
/*********** 5.SIDEBAR ***********/
.side-menu {
	width: 240px;
	top: 0;
	bottom: 0;
	z-index: 2;
}
.side-menu.left {
	background: #293b5a;
	position: absolute;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
	top: 70px;
	z-index: 9999;
}
body.fixed-left .side-menu.left {
	margin-top: 0px;
	position: fixed;
	height: 100%;
	bottom: 50px;
	margin-bottom: -70px;
	padding-bottom: 70px;
}
.content-page {
	margin-left: 240px;
	overflow: hidden;
	position: relative;
	min-height: 940px;
}
.content-page > .content {
	margin-top: 70px;
	margin-bottom: 60px;
	padding: 20px 5px 15px 10px;
	position: relative;
}
.button-menu-mobile {
	font-size: 21px;
	background: transparent;
	color: #7b96c4;
	border: none;
	line-height: 70px;
	color: #7b96c4;
	padding: 0px 15px;
}
.button-menu-mobile:hover {
	color: #21324f;
}
.sidebar-inner {
	height: 100%;
}
#sidebar-menu, #sidebar-menu ul, #sidebar-menu li, #sidebar-menu a {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	font-weight: normal;
	text-decoration: none;
	line-height: 1;
	position: relative;
}
#sidebar-menu a {
	line-height: 1.3;
}
#sidebar-menu {
	width: 100%;
	background-color: #293b5a;
	padding-bottom: 50px;
}
#sidebar-menu > ul > li > a {
	display: block;
	color: #7b96c4;
	font-size: 14px;
	text-transform: uppercase;
	padding: 24px 13px;
}
#sidebar-menu > ul > li > a > span {
	vertical-align: middle;
}
#sidebar-menu > ul > li > a:hover {
	background: transparent;
	color: #55e4d4;
}
#sidebar-menu > ul > li > a > i {
	display: inline-block;
	font-size: 24px;
	line-height: 16px;
	margin-left: 3px;
	margin-right: 15px;
	text-align: center;
	vertical-align: middle;
	width: 20px;
	margin-top: -3px;
}
#sidebar-menu > ul > li > a > i.i-right {
	margin: 3px 0 0 0;
	float: right;
}
#sidebar-menu > ul > li > a:hover {
	text-decoration: none;
}
#sidebar-menu > ul > li > a.active, #sidebar-menu > ul > li > a.active.subdrop {
	background: transparent !important;
	color: #55e4d4;
}
.subdrop {
	background: #f3f3f3;
}
#sidebar-menu ul ul {
	display: none;
}
#sidebar-menu ul ul li {
	border-top: 0;
}
#sidebar-menu ul ul li.active a {
	color: #317eeb;
}
#sidebar-menu ul ul a {
	padding: 10px 25px 10px 65px;
	display: block;
	color: #75798B;
}
#sidebar-menu ul ul a:hover {
	color: #317eeb;
}
#sidebar-menu ul ul ul a {
	padding-left: 80px;
}
#sidebar-menu ul ul a i {
	margin-right: 5px;
}
#wrapper.enlarged #sidebar-menu ul ul {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
#wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a {
	padding-left: 10px;
}
#wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a:hover {
	background-color: #f3f3f3 !important;
}
#wrapper.enlarged .left.side-menu {
	width: 50px;
	z-index: 5;
}
#wrapper.enlarged .content-page {
	margin-left: 50px;
}
#wrapper.enlarged .topbar .topbar-left {
	width: 50px !important;
}
#wrapper.enlarged .topbar .topbar-left .logo span {
	opacity: 0;
	display: none;
}
#wrapper.enlarged .left.side-menu span.pull-right {
	display: none !important;
}
#wrapper.enlarged .topbar .topbar-left .logo i {
	margin-right: 0px;
}
#wrapper.enlarged .left.side-menu #sidebar-menu ul > li {
	white-space: nowrap;
}
#wrapper.enlarged .left.side-menu #sidebar-menu ul > li > ul {
	display: none;
}
#wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > a {
	background: #ffffff;
}
#wrapper.enlarged #sidebar-menu > ul > li:hover > a.open:after, #wrapper.enlarged #sidebar-menu > ul > li:hover > a.active:after {
	display: none;
}
#wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > ul {
	position: absolute;
	left: 50px;
	width: 190px;
	display: block;
}
#wrapper.enlarged .left.side-menu #sidebar-menu ul ul li:hover > ul {
	position: absolute;
	left: 190px;
	margin-top: -36px;
	width: 190px;
	display: block;
}
#wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > ul a {
	background: #fff;
	padding-left: 15px;
	border: none;
	width: 190px;
	box-shadow: none;
	z-index: 6;
	position: relative;
	border-left: 3px solid #edf0f0;
}
#wrapper.enlarged .left.side-menu #sidebar-menu ul ul li > a span.pull-right {
	position: absolute;
	right: 20px;
	top: 12px;
	-ms-transform: rotate(270deg);
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
}
#wrapper.enlarged .left.side-menu #sidebar-menu ul > li > a span {
	display: none;
	padding-left: 10px;
}
#wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > a {
	width: 240px;
	position: relative;
}
#wrapper.enlarged .left.side-menu #sidebar-menu ul > li {
	position: relative;
}
#wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover a span {
	display: inline;
}
/* Leftbar Profile */
#wrapper.enlarged .left.side-menu .user-details {
	display: none;
}
#wrapper.enlarged .left.side-menu {
	padding-top: 0px;
	margin-top: -10px;
	z-index: 999999!important;
}
.user-details {
	position: relative;
	min-height: 80px;
	padding: 20px;
}
.user-details img {
	position: relative;
	z-index: 9999;
}
.user-details .user-info {
	margin-left: 60px;
	z-index: 99999;
	position: relative;
	color: #444;
}
.user-details .user-info a.dropdown-toggle {
	font-family: 'work_sansregular';
	font-weight: 600;
	color: #666;
	font-size: 16px;
	padding-top: 5px;
	display: block;
}
#wrapper.right-bar-enabled .right-bar {
	right: 0;
}
#wrapper.right-bar-enabled .left-layout {
	left: 0;
}
.side-bar.right-bar {
	float: right !important;
	right: -266px;
	top: 70px;
}
.side-bar {
	position: fixed;
	height: 100%;
	width: 240px;
	background-color: #ffffff;
	display: block;
	float: left;
	box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 200ms ease-out;
	-moz-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
	overflow-y: auto;
}
/************* 6.RIGHT SIDEBAR ************/
.right-bar {
	z-index: 99 !important;
	background: #ffffff !important;
}
.right-bar h4 {
	border-bottom: 1px solid #eee;
	padding-bottom: 10px;
}
.contact-list {
	max-height: 600px;
}
.contact-list .list-group-item {
	border: none;
}
.contact-list .list-group-item:hover {
	background: #f5f5f5;
}
.contacts-list .avatar {
	width: 30px;
	display: inline-block;
	margin-right: 5px;
	float: left;
}
.contacts-list .avatar img {
	border-radius: 50%;
	width: 100%;
}
.contacts-list .list-group-item span.name {
	white-space: nowrap;
	width: 130px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	float: left;
	padding-top: 6px;
	padding-left: 5px;
	color: #707780;
}
.contacts-list i.online {
	color: #a0d269;
}
.contacts-list i {
	float: right;
	line-height: 30px;
	color: #ddd;
	font-size: 9px;
}
.contact-list i.offline {
	color: #ef5350;
}
.contact-list i.away {
	color: #ffd740;
}
/************ 7.Email ************/
.mails a {
	color: #444;
}
.mails .checkbox {
	margin-top: 0px;
	margin-bottom: 0px;
	vertical-align: middle;
}
.mails .checkbox label {
	min-height: 16px;
}
/************ 8.Calendar ************/
.calendar {
	margin-bottom: 0px;
	float: left;
}
.fc-toolbar {
	margin-bottom: 5px;
}
.fc-toolbar h2 {
	font-size: 18px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 30px;
}
.fc-day {
	background: #FFF;
}
.fc-toolbar .fc-state-active, .fc-toolbar .ui-state-active, .fc-toolbar button:focus, .fc-toolbar button:hover, .fc-toolbar .ui-state-hover {
	z-index: 0;
}
.fc-widget-header, .fc-widget-content {
	border: 1px solid #D5D5D5;
}
.fc th.fc-widget-header {
	background: #ddd;
	font-size: 14px;
	text-transform: uppercase;
	line-height: 20px;
	padding: 10px 0px;
}
.fc-button {
	border: 1px solid #D5D5D5;
	background: #FFF;
	color: #555;
	text-transform: capitalize;
}
.fc-text-arrow, .fc-text-arrow {
	font-family: 'work_sansregular';
	font-size: 16px;
}
.fc-state-hover {
	background: #F5F5F5;
}
.fc-state-highlight, .fc-cell-overlay {
	background: #F0F0F0;
}
.fc-unthemed .fc-today {
	background: #fff;
}
.fc-event {
	font-size: 13px;
	padding: 5px 5px;
	text-align: center;
	margin: 5px 7px;
	border: none;
	border-radius: 2px;
	cursor: move;
}
.external-event {
	cursor: move;
	margin: 10px 0;
	padding: 6px 10px;
	color: #ffffff;
}
.fc-basic-view td.fc-week-number span, .fc-basic-view td.fc-day-number {
	padding-right: 5px;
}
/************ 9.CHECKBOX-RADIO BUTTON ************/

.checkbox {
	padding-left: 20px;
}
.checkbox label {
	display: inline-block;
	position: relative;
	padding-left: 5px;
}
.checkbox label::before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 17px;
	height: 17px;
	left: 0;
	margin-left: -20px;
	border: 1px solid #cccccc;
	border-radius: 3px;
	background-color: #fff;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}
.checkbox label::after {
	display: inline-block;
	position: absolute;
	width: 16px;
	height: 16px;
	left: 0;
	top: 0;
	margin-left: -20px;
	padding-left: 3px;
	padding-top: 1px;
	font-size: 11px;
	color: #555555;
}
.checkbox input[type="checkbox"] {
	opacity: 0;
	z-index: 1;
	cursor: pointer;
}
.checkbox input[type="checkbox"]:focus + label::before {
	outline: thin dotted;
	outline: none;
	outline-offset: -2px;
}
.checkbox input[type="checkbox"]:checked + label::after {
	font-family: 'FontAwesome';
	content: "\f00c";
}
.checkbox input[type="checkbox"]:disabled + label {
	opacity: 0.65;
}
.checkbox input[type="checkbox"]:disabled + label::before {
	background-color: #eeeeee;
	cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
	border-radius: 50%;
}
.checkbox.checkbox-inline {
	margin-top: 0;
}
.checkbox.checkbox-single label {
	height: 17px;
}
.checkbox-primary input[type="checkbox"]:checked + label::before {
	background-color: #1e88e5;
	border-color: #1e88e5;
}
.checkbox-primary input[type="checkbox"]:checked + label::after {
	color: #fff;
}
.checkbox-danger input[type="checkbox"]:checked + label::before {
	background-color: #ef5350;
	border-color: #ef5350;
}
.checkbox-danger input[type="checkbox"]:checked + label::after {
	color: #fff;
}
.checkbox-info input[type="checkbox"]:checked + label::before {
	background-color: #29b6f6;
	border-color: #29b6f6;
}
.checkbox-info input[type="checkbox"]:checked + label::after {
	color: #fff;
}
.checkbox-warning input[type="checkbox"]:checked + label::before {
	background-color: #ffd740;
	border-color: #ffd740;
}
.checkbox-warning input[type="checkbox"]:checked + label::after {
	color: #fff;
}
.checkbox-success input[type="checkbox"]:checked + label::before {
	background-color: #33b86c;
	border-color: #33b86c;
}
.checkbox-success input[type="checkbox"]:checked + label::after {
	color: #fff;
}
.radio {
	padding-left: 20px;
}
.radio label {
	display: inline-block;
	position: relative;
	padding-left: 5px;
}
.radio label::before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 17px;
	height: 17px;
	left: 0;
	outline: none !important;
	margin-left: -20px;
	border: 1px solid #cccccc;
	border-radius: 50%;
	background-color: #fff;
	-webkit-transition: border 0.5s ease-in-out;
	-o-transition: border 0.5s ease-in-out;
	transition: border 0.5s ease-in-out;
}
.radio label::after {
	display: inline-block;
	position: absolute;
	content: " ";
	width: 11px;
	height: 11px;
	left: 3px;
	top: 3px;
	margin-left: -20px;
	border-radius: 50%;
	background-color: #555555;
	-webkit-transform: scale(0, 0);
	-ms-transform: scale(0, 0);
	-o-transform: scale(0, 0);
	transform: scale(0, 0);
	-webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
	-moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
	-o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
	transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.radio input[type="radio"] {
	opacity: 0;
	z-index: 1;
	cursor: pointer;
}
.radio input[type="radio"]:focus + label::before {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.radio input[type="radio"]:checked + label::after {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	-o-transform: scale(1, 1);
	transform: scale(1, 1);
}
.radio input[type="radio"]:disabled + label {
	opacity: 0.65;
}
.radio input[type="radio"]:disabled + label::before {
	cursor: not-allowed;
}
.radio.radio-inline {
	margin-top: 0;
}
.radio.radio-single label {
	height: 17px;
}
.radio-primary input[type="radio"] + label::after {
	background-color: #1e88e5;
}
.radio-primary input[type="radio"]:checked + label::before {
	border-color: #1e88e5;
}
.radio-primary input[type="radio"]:checked + label::after {
	background-color: #1e88e5;
}
.radio-danger input[type="radio"] + label::after {
	background-color: #ef5350;
}
.radio-danger input[type="radio"]:checked + label::before {
	border-color: #ef5350;
}
.radio-danger input[type="radio"]:checked + label::after {
	background-color: #ef5350;
}
.radio-info input[type="radio"] + label::after {
	background-color: #29b6f6;
}
.radio-info input[type="radio"]:checked + label::before {
	border-color: #29b6f6;
}
.radio-info input[type="radio"]:checked + label::after {
	background-color: #29b6f6;
}
.radio-warning input[type="radio"] + label::after {
	background-color: #ffd740;
}
.radio-warning input[type="radio"]:checked + label::before {
	border-color: #ffd740;
}
.radio-warning input[type="radio"]:checked + label::after {
	background-color: #ffd740;
}
.radio-success input[type="radio"] + label::after {
	background-color: #33b86c;
}
.radio-success input[type="radio"]:checked + label::before {
	border-color: #33b86c;
}
.radio-success input[type="radio"]:checked + label::after {
	background-color: #33b86c;
}
/******************* 10.Widget ***************/
.widget-s-1 {
	border-radius: 6px;
}
.mini-stat {
	background: #f8f8f8;
	padding: 0;
	margin-bottom: 20px;
}
.mini-stat-icon {
	width: 30px;
	height: 60px;
	display: inline-block;
	line-height: 60px;
	padding-top: 10px;
	float: left;
	margin-right: 0;
}
.mini-stat-info {
	padding-top: 0px;
}
.mini-stat-info span {
	display: block;
	font-size: 24px;
	font-weight: 600;
	color: #555;
}
.mini-stat-info span.name {
	display: block;
	color: rgba(0,0,0,0.87);
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
}
.inbox-widget .inbox-item {
	padding: 10px 0;
	border-bottom: 1px solid #f1f1f1;
	overflow: hidden;
	position: relative;
}
.inbox-widget .inbox-item .inbox-item-img {
	float: left;
	width: 2px;
	display: block;
	margin-right: 15px;
	margin-top: 5px;
}
.inbox-widget .inbox-item span {
	height: 8px;
	width: 8px;
	background-color: #E1024F;
	float: left;
	border-radius: 50px;
}
.inbox-widget .inbox-item .inbox-item-author {
	color: #333;
	display: block;
	margin: 0;
	color: rgba(0,0,0,0.87);
	font-size: 16px;
}
.inbox-widget .inbox-item .inbox-item-text {
	color: #a0a0a0;
	display: block;
	margin: 0;
	font-size: 12px;
}
.inbox-widget .inbox-item .inbox-item-text.active {
	color: #a0a0a0;
	display: block;
	margin: 0;
	color: rgba(0,0,0,0.87);
	font-size: 16px;
	font-weight: 600;
	padding-left: 18px;
	margin-top: 8px;
}
.inbox-widget .inbox-item .inbox-item-text {
	color: #a0a0a0;
	display: block;
	margin: 0;
	font-size: 12px;
	font-size: 16px;
	padding-left: 18px;
	margin-top: 8px;
	color: rgba(0,0,0,0.54);
}
.inbox-widget .inbox-item span.active {
	height: 8px;
	width: 8px;
	background-color: #fff;
	float: left;
	border-radius: 50px;
}
.inbox-widget .inbox-item .inbox-item-author {
	color: #333;
	display: block;
	margin: 0;
	color: rgba(0,0,0,0.87);
	font-size: 16px;
}
.inbox-widget .inbox-item .inbox-item-author.active {
	color: #333;
	display: block;
	margin: 0;
	color: rgba(0,0,0,0.87);
	font-size: 16px;
	font-weight: 600;
}
.inbox-widget .inbox-item .inbox-item-date.active {
	position: absolute;
	top: 9px;
	right: 7px;
	color: #000;
	font-weight: 600!important;
	font-size: 14px;
	line-height: 23px;
}
.inbox-widget .inbox-item .inbox-item-date {
	position: absolute;
	top: 7px;
	right: 7px;
	color: #a9a9a9;
	font-size: 11px;
	color: rgba(0,0,0,0.54);
	font-size: 14px;
	line-height: 23px;
}
/* Chat / Todo */
.conversation-list {
	list-style: none;
	padding: 0px 20px;/*max-height: 330px;*/
}
.conversation-list li {
	margin-bottom: 24px;
}
.conversation-list .chat-avatar {
	width: 40px;
	display: inline-block;
	text-align: center;
	float: left;
}
.conversation-list .chat-avatar img {
	width: 100%;
	border-radius: 100%;
}
.conversation-list .chat-avatar i {
	font-size: 12px;
	font-style: normal;
}
.conversation-list .ctext-wrap i {
	display: block;
	font-style: normal;
	font-weight: bold;
	position: relative;
	font-size: 12px;
	color: #1a2942;
}
.conversation-list .conversation-text {
	display: inline-block;
	font-size: 12px;
	float: left;
	margin-left: 12px;
	width: 70%;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}
.conversation-list .ctext-wrap {
	padding: 10px;
	background: #F5F5F5;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	position: relative;
	display: inline-block;
	border-radius: 18px 18px 18px 0;
	background-color: #FFFFFF;
}
.conversation-list .ctext-wrap p {
	margin: 0px;
	padding-top: 3px;
}
.conversation-list .odd .chat-avatar {
	float: right !important;
}
.conversation-list .odd .conversation-text {
	width: 70% !important;
	margin-right: 12px;
	text-align: right;
	float: right !important;
}
.conversation-list .odd .ctext-wrap {
	background: #30DBD0 !important;
	color: #fff;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	border-radius: 18px 18px 0px 18px;
}
.conversation-list .odd .ctext-wrap i {
	color: #fff;
}
.conversation-list .odd .ctext-wrap:after {
	left: 100% !important;
	top: 20% !important;
	border-color: rgba(238,238,242,0)!important;
	border-left-color: #e8e9ec !important;
}
.chat-send {
	padding-left: 0px;
	padding-right: 30px;
}
.chat-send button {
	width: 100%;
}
.chat-inputbar {
	padding-left: 30px;
}
/*ToDo List*/
#todo-message {
	font-size: 16px;
}
.todoapp {
	padding: 10px 30px 20px 30px !important;
}
.todo-list li {
	border: 0px;
	margin: 0px;
	border-radius: 0px;
	border-bottom: 1px solid #eee;
	padding-left: 0px !important;
	padding-right: 0px !important;
}
.todo-list li:last-of-type {
	border-bottom: none;
}
.todo-send {
	padding-left: 0px;
}
/************ 11.Gallery ************/
.portfolioFilter a {
	padding: 5px 10px;
	color: #333;
	-webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}
.portfolioFilter a:hover, .portfolioFilter a.current {
	background-color: #317eeb;
	color: #ffffff;
}
.thumb {
	background-color: #ffffff;
	border-radius: 3px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
	width: 100%;
	padding-top: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	margin-top: 30px;
}
.thumb-img {
	width: 100%;
	overflow: hidden;
	border-radius: 2px;
}
.gal-detail h4 {
	margin-top: 16px;
}
/************ 12. Pricing Tables ************/
.pricing-plan {
	padding-bottom: 50px;
}
.price_card {
	background: var(--secondary-white);
	position: relative;
	padding-bottom: 20px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin-bottom: 30px;
	webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
}
.pricing-header {
	color: rgb(255, 255, 255);
	-webkit-border-top-left-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-topright: 5px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
.price {
	width: 100%;
	font-size: 48px;
	font-weight: 300;
	display: block;
	text-align: center;
	padding: 30px 0 10px;
}
.price_card .name {
	width: 100%;
	font-size: 16px;
	font-weight: 700;
	display: block;
	text-align: center;
	padding: 0 0 30px;
}
.price-features {
	list-style: none;
	text-align: center;
	color: rgb(138, 138, 138);
	margin: 0;
	padding: 0;
}
.price-features li {
	margin: 0 35px;
	padding: 20px 15px;
}
.price-features li:not(:last-child) {
	border: 1px solid rgb(242, 242, 242);
	border-top: 0;
	border-left: 0;
	border-right: 0;
}
.price_card button {
	margin-top: 20px;
}
/* Pricing-2 */
.pricing-item {
	margin: 0 0 30px;
	position: relative;
	text-align: center;
}
.pricing-item-inner {
	border: 2px solid rgba(151, 160, 175, 0.2);
	vertical-align: middle;
	border-radius: 7px;
	-webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
	-moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
	-o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
	-ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
	transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.pricing-wrap {
	position: relative;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 30px 20px;
	text-align: center;
	overflow: hidden;
}
.pricing-icon {
	padding-top: 10px;
	padding-bottom: 5px;
	position: relative;
	font-size: 32px;
	z-index: 1;
}
.pricing-title {
	position: relative;
	margin-bottom: 40px;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	z-index: 1;
}
.pr-list {
	margin: 0 -20px 30px;
	padding: 0;
	color: #97a0af;
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
}
.pr-list li {
	padding: 12px 20px;
	list-style: none;
}
.pricing-num {
	font-size: 40px;
	font-weight: 700;
	line-height: 1;
}
.pricing-num sup {
	font-size: 18px;
	font-weight: 400;
	position: relative;
	top: -20px;
	left: -3px;
	margin-left: -7px;
}
.pr-per {
	color: #97a0af;
	font-size: 12px;
}
.pr-button {
	margin-top: 30px;
}
/************ 13. FORMS ***********/
/* Form-validation */
.error {
	color: #ff0000;
}
/* Datapicker */
.datepicker {
	border: 1px solid #ddd;
	padding: 8px;
}
.datepicker th {
	font-size: 14px !important;
}
/* Multiple Select */
.search-input {
	margin-bottom: 10px;
}
.ms-selectable {
	outline: none !important;
	box-shadow: none;
}
.ms-container .ms-list.ms-focus {
	box-shadow: none;
}
.ms-container .ms-selectable li.ms-hover, .ms-container .ms-selection li.ms-hover {
	background-color: #317eeb;
}
/* spinner */
.spinner-buttons.btn-group-vertical .btn {
	height: 17px;
	margin: 0;
	padding-left: 6px;
	padding-right: 6px;
	text-align: center;
	width: 22px;
	line-height: 16px;
	box-shadow: none !important;
	border: none !important;
	background-color: #eeeeee;
}
.spinner-buttons.btn-group-vertical .btn i {
	margin-top: -3px;
	line-height: 10px;
	color: #333 !important
}
.spinner-buttons.btn-group-vertical .btn:first-child {
	border-radius: 0 0px 0 0 !important;
	-webkit-border-radius: 0 0px 0 0 !important;
}
.spinner-buttons.btn-group-vertical .btn:last-child {
	border-radius: 0 0 0px !important;
	-webkit-border-radius: 0 0 0px !important;
}
/* summernote */
.note-editor .note-toolbar {
	background-color: #f3f3f3;
	border-bottom: 1px solid #dfdfdf;
	margin: 0;
}
.note-editor {
	position: relative;
	border: 1px solid #dfdfdf;
}
.note-editor .note-statusbar {
	background-color: #fff;
}
.note-editor .note-statusbar .note-resizebar {
	height: 15px;
	border-top: 1px solid #dfdfdf;
	padding-top: 3px;
}
.note-popover .popover .popover-content, .note-toolbar {
	padding: 5px 0 10px 5px;
}
/* Code Editer */
.code-edit-wrap {
	padding: 0px !important;
}
.cm-s-ambiance .CodeMirror-linenumber {
	color: #bcbcbc;
}
.cm-s-ambiance.CodeMirror, .cm-s-ambiance .CodeMirror-gutters {
	background-color: #212121 !important;
	box-shadow: none;
}
/* Form- Pluins */
.bootstrap-timepicker-widget table td a:hover {
	text-decoration: none;
	background-color: transparent;
	border-radius: 4px;
	border-color: transparent;
	color: #317eeb;
}
/* X-editor */
.editor-horizontal .popover-content {
	padding: 9px 30px;
}
/* form wizard--*/
.wizard > .content {
	background: #fff;
	min-height: 240px;
}
.wizard > .content > .body {
	padding: 0px;
}
.wizard > .content > .body input {
	border: 1px solid #eeeeee;
}
.wizard > .content > .body ul > li {
	display: block;
}
.wizard > .steps .number {
	display: inline-block;
	line-height: 30px;
	border-radius: 50%;
	margin-right: 10px;
}
.wizard > .steps .disabled a, .wizard > .steps .disabled a:hover, .wizard > .steps .disabled a:active {
	background: #f2f2f2;
	color: #323232;
	cursor: default;
}
.wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active, .wizard > .content {
	border-radius: 2px;
}
.wizard > .steps .current a, .wizard > .steps .current a:hover, .wizard > .steps .current a:active {
	background: #317eeb;
}
.wizard > .steps .current a .number, .wizard > .steps .current a:hover .number, .wizard > .steps .current a:active .number {
	background: transparent;
	color: #ffffff;
}
.wizard > .steps .done a, .wizard > .steps .done a:hover, .wizard > .steps .done a:active {
	background: #cccccc;
}
.wizard > .content > .body label.error {
	margin-left: 0;
	color: #e55957;
}
.wizard > .actions {
	margin-bottom: 30px;
}
.wizard > .actions a, .wizard > .actions a:hover, .wizard > .actions a:active {
	background: #317eeb;
	color: #fff;
	border-radius: 2px;
}
.wizard > .actions .disabled a, .wizard > .actions .disabled a:hover, .wizard > .actions .disabled a:active {
	background: #e2e2e2;
	color: #323232;
}
.wizard > .content > .body label {
	display: inline-block;
	margin-top: 10px;
}
.wizard > .content > .body ul > li {
	display: block;
	line-height: 30px;
}
/* Datapiker */
.datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover {
	box-shadow: none;
	background-color: #1a2942 !important;
	background-image: none;
}
/********** 14. TABLES **************/
.table {
	margin-bottom: 10px;
}
tbody {
	color: #797979;
}
th {
	font-size: 15px;
	color: #666;
}
/* Responsive Table */
table.focus-on tbody tr.focused th, table.focus-on tbody tr.focused td {
	background-color: #317eeb;
	color: #ffffff;
}
.table-rep-plugin tbody th {
	font-weight: normal;
	font-size: 14px;
}
/* Editable Table */
.modal-block {
	background: transparent;
	padding: 0;
	text-align: left;
	max-width: 600px;
	margin: 40px auto;
	position: relative;
}
#datatable-editable .actions a {
	padding: 5px;
}
#datatable-editable .form-control {
	background-color: #ffffff;
	width: 100%;
}
#datatable-editable .fa-trash-o, #datatable-editable .fa-times {
	color: #ef5350;
}
#datatable-editable .fa-pencil {
	color: #29b6f6;
}
#datatable-editable .fa-save {
	color: #33b86c;
}
/* Datatable */
#datatable td {
	font-weight: normal;
}
div.dataTables_paginate ul.pagination {
	margin-top: 30px;
}
div.dataTables_info {
	padding-top: 38px;
}
/********* 15. CHARTS *******************/
/* Sparkline-Chart */
.jqstooltip {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}
/** EASY PIE CHART **/
.chart {
	position: relative;
	display: inline-block;
	width: 110px;
	height: 110px;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
}
.chart.chart-widget-pie {
	margin-top: 5px;
	margin-bottom: 5px;
}
.chart canvas {
	position: absolute;
	top: 0;
	left: 0;
}
.percent {
	display: inline-block;
	line-height: 110px;
	z-index: 2;
}
.percent:after {
	content: '%';
	margin-left: 0.1em;
	font-size: .8em;
}
/* Flot-chart */
#flotTip {
	padding: 4px 8px;
	background-color: #000;
	z-index: 100;
	color: #FFF;
	opacity: .7;
	font-size: 12px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
/********* 16. MAPS *******************/
.gmaps, .gmaps-panaroma {
	height: 300px;
	background: #eeeeee;
	border-radius: 3px;
}
.gmaps-overlay {
	display: block;
	text-align: center;
	color: #fff;
	font-size: 16px;
	line-height: 40px;
	background: #317eeb;
	border-radius: 4px;
	padding: 10px 20px;
}
.gmaps-overlay_arrow {
	left: 50%;
	margin-left: -16px;
	width: 0;
	height: 0;
	position: absolute;
}
.gmaps-overlay_arrow.above {
	bottom: -15px;
	border-left: 16px solid transparent;
	border-right: 16px solid transparent;
	border-top: 16px solid #317eeb;
}
.gmaps-overlay_arrow.below {
	top: -15px;
	border-left: 16px solid transparent;
	border-right: 16px solid transparent;
	border-bottom: 16px solid #317eeb;
}
/************* 17.PAGES ****************/

.bg-overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(49, 126, 235, 0.4);
	top: 0px;
	left: 0px;
	border-radius: 6px 6px 0px 0px;
	-moz-border-radius: 6px 6px 0px 0px;
	-webkit-border-radius: 6px 6px 0px 0px;
}
/* 17.1 Profile */
.bg-picture {
	position: relative;
	padding: 100px 0px;
	margin: -20px -25px 0px -25px;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	background-size: cover;
}
.bg-picture>.bg-picture-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
 background-image: url(data:image/svg+xml;
base64, PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dâ€¦0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
	background-image: -webkit-linear-gradient(top, rgba(255,255,255,0) 0, rgba(0,0,0,.4) 100%);
	background-image: -o-linear-gradient(top, rgba(255,255,255,0) 0, rgba(0,0,0,.4) 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)), to(rgba(0,0,0,.4)));
	background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0, rgba(0,0,0,.4) 100%);
}
.profile-info-name {
	position: relative;
}
.profile-tab-content {
	background-color: transparent !important;
	box-shadow: none !important;
	margin-top: 35px;
}
.user-tabs {
	margin-left: -35px;
	padding-left: 25px;
	background-color: #ffffff;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.user-tabs .nav.nav-tabs {
	box-shadow: none !important;
}
.user-tabs .nav.nav-tabs a {
	text-transform: uppercase;
}
.user-tabs .pull-right .btn {
	margin-top: 8px;
}
.about-info-p {
	margin-bottom: 20px;
}
.about-info-p p {
	font-size: 16px;
}
/* Timeline-2 */
.timeline-2 {
	position: relative;
	border-left: 2px solid #14082d;
}
.time-item {
	position: relative;
	padding-bottom: 1px;
	border-color: #dee5e7;
}
.time-item:before, .time-item-item:after {
	display: table;
	content: " ";
}
.timeline-2 .time-item:after, .time-item:after {
	position: absolute;
	bottom: 0;
	left: 0;
	top: 5px;
	width: 14px;
	height: 14px;
	margin-left: -8px;
	background-color: #fff;
	border-color: #14082d;
	border-style: solid;
	border-width: 2px;
	border-radius: 10px;
	content: '';
}
.item-info {
	margin-left: 15px;
	margin-bottom: 15px;
}
.item-info p {
	margin-bottom: 10px !important;
}
/* 17.2 TIMELINE */
.cd-container {
	width: 90%;
	max-width: 1170px;
	margin: 0 auto;
}
.cd-container::after {
	content: '';
	display: table;
	clear: both;
}
#cd-timeline {
	position: relative;
	padding: 2em 0;
	margin-top: 2em;
	margin-bottom: 2em;
}
#cd-timeline::before {
	content: '';
	position: absolute;
	top: 0;
	left: 18px;
	height: 100%;
	width: 4px;
	background: #fff;
}

@media only screen and (min-width: 1170px) {
#cd-timeline {
	margin-top: 3em;
	margin-bottom: 3em;
}
#cd-timeline::before {
	left: 50%;
	margin-left: -2px;
}
}
.cd-timeline-block {
	position: relative;
	margin: 2em 0;
}
.cd-timeline-block:after {
	content: "";
	display: table;
	clear: both;
}
.cd-timeline-block:first-child {
	margin-top: 0;
}
.cd-timeline-block:last-child {
	margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
.cd-timeline-block {
	margin: 4em 0;
}
.cd-timeline-block:first-child {
	margin-top: 0;
}
.cd-timeline-block:last-child {
	margin-bottom: 0;
}
}
.cd-timeline-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
	text-align: center;
	line-height: 40px;
	font-size: 20px;
	color: #fff;
}
.cd-timeline-img.cd-success {
	background: #33b86c;
}
.cd-timeline-img.cd-info {
	background: #29b6f6;
}
.cd-timeline-img.cd-pink {
	background: #ec407a;
}
.cd-timeline-img.cd-danger {
	background: #ef5350;
}
.cd-timeline-img.cd-primary {
	background: #1e88e5;
}
.cd-timeline-img.cd-warning {
	background: #ffd740;
}

@media only screen and (min-width: 1170px) {
.cd-timeline-img {
	width: 60px;
	height: 60px;
	line-height: 60px;
	left: 50%;
	margin-left: -30px;
	-webkit-transform: translateZ(0);
	-webkit-backface-visibility: hidden;
}
.cssanimations .cd-timeline-img.is-hidden {
	visibility: hidden;
}
.cssanimations .cd-timeline-img.bounce-in {
	visibility: visible;
	-webkit-animation: cd-bounce-1 0.6s;
	-moz-animation: cd-bounce-1 0.6s;
	animation: cd-bounce-1 0.6s;
}
}
 @-webkit-keyframes cd-bounce-1 {
 0% {
 opacity: 0;
 -webkit-transform: scale(0.5);
}
 60% {
 opacity: 1;
 -webkit-transform: scale(1.2);
}
 100% {
 -webkit-transform: scale(1);
}
}
@-moz-keyframes cd-bounce-1 {
 0% {
 opacity: 0;
 -moz-transform: scale(0.5);
}
 60% {
 opacity: 1;
 -moz-transform: scale(1.2);
}
 100% {
 -moz-transform: scale(1);
}
}
@keyframes cd-bounce-1 {
 0% {
 opacity: 0;
 -webkit-transform: scale(0.5);
 -moz-transform: scale(0.5);
 -ms-transform: scale(0.5);
 -o-transform: scale(0.5);
 transform: scale(0.5);
}
 60% {
 opacity: 1;
 -webkit-transform: scale(1.2);
 -moz-transform: scale(1.2);
 -ms-transform: scale(1.2);
 -o-transform: scale(1.2);
 transform: scale(1.2);
}
 100% {
 -webkit-transform: scale(1);
 -moz-transform: scale(1);
 -ms-transform: scale(1);
 -o-transform: scale(1);
 transform: scale(1);
}
}
.cd-timeline-content {
	position: relative;
	margin-left: 60px;
	background: white;
	border-radius: 0;
	padding: 1em;
	-webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
	box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
}
.cd-timeline-content img {
	width: 100%;
	display: block;
}
.cd-timeline-content:after {
	content: "";
	display: table;
	clear: both;
}
.cd-timeline-content h2 {
	margin-top: 0;
}
.cd-timeline-content p, .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
	font-size: 14px;
}
.cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
	display: inline-block;
}
.cd-timeline-content h3 {
	margin: 0px;
	font-size: 21px;
}
.cd-timeline-content p {
	margin: 10px 0px 10px 0px;
	color: #666;
}
.cd-timeline-content .cd-read-more {
	float: right;
	padding: .8em 1em;
	background: #acb7c0;
	color: white;
	border-radius: 0.25em;
}
.no-touch .cd-timeline-content .cd-read-more:hover {
	background-color: #bac4cb;
}
.cd-timeline-content .cd-date {
	float: left;
	padding: .8em 0;
	opacity: .7;
}
.cd-timeline-content::before {
	content: '';
	position: absolute;
	top: 16px;
	right: 100%;
	height: 0;
	width: 0;
	border: 7px solid transparent;
	border-right: 7px solid white;
}

@media only screen and (min-width: 1170px) {
.cd-timeline-content {
	margin-left: 0;
	padding: 1.6em;
	width: 45%;
}
.cd-timeline-content::before {
	top: 24px;
	left: 100%;
	border-color: transparent;
	border-left-color: white;
}
.cd-timeline-content .cd-read-more {
	float: left;
}
.cd-timeline-content .cd-date {
	position: absolute;
	width: 100%;
	left: 122%;
	top: 6px;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content {
	float: right;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content::before {
	top: 24px;
	left: auto;
	right: 100%;
	border-color: transparent;
	border-right-color: white;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
	float: right;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
	left: auto;
	right: 122%;
	text-align: right;
}
.cssanimations .cd-timeline-content.is-hidden {
	visibility: hidden;
}
.cssanimations .cd-timeline-content.bounce-in {
	visibility: visible;
	-webkit-animation: cd-bounce-2 0.6s;
	-moz-animation: cd-bounce-2 0.6s;
	animation: cd-bounce-2 0.6s;
}
}

@media only screen and (min-width: 1170px) {
.cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
	-webkit-animation: cd-bounce-2-inverse 0.6s;
	-moz-animation: cd-bounce-2-inverse 0.6s;
	animation: cd-bounce-2-inverse 0.6s;
}
}
@-webkit-keyframes cd-bounce-2 {
 0% {
 opacity: 0;
 -webkit-transform: translateX(-100px);
}
 60% {
 opacity: 1;
 -webkit-transform: translateX(20px);
}
 100% {
 -webkit-transform: translateX(0);
}
}
@-moz-keyframes cd-bounce-2 {
 0% {
 opacity: 0;
 -moz-transform: translateX(-100px);
}
 60% {
 opacity: 1;
 -moz-transform: translateX(20px);
}
 100% {
 -moz-transform: translateX(0);
}
}
@keyframes cd-bounce-2 {
 0% {
 opacity: 0;
 -webkit-transform: translateX(-100px);
 -moz-transform: translateX(-100px);
 -ms-transform: translateX(-100px);
 -o-transform: translateX(-100px);
 transform: translateX(-100px);
}
 60% {
 opacity: 1;
 -webkit-transform: translateX(20px);
 -moz-transform: translateX(20px);
 -ms-transform: translateX(20px);
 -o-transform: translateX(20px);
 transform: translateX(20px);
}
 100% {
 -webkit-transform: translateX(0);
 -moz-transform: translateX(0);
 -ms-transform: translateX(0);
 -o-transform: translateX(0);
 transform: translateX(0);
}
}
@-webkit-keyframes cd-bounce-2-inverse {
 0% {
 opacity: 0;
 -webkit-transform: translateX(100px);
}
 60% {
 opacity: 1;
 -webkit-transform: translateX(-20px);
}
 100% {
 -webkit-transform: translateX(0);
}
}
@-moz-keyframes cd-bounce-2-inverse {
 0% {
 opacity: 0;
 -moz-transform: translateX(100px);
}
 60% {
 opacity: 1;
 -moz-transform: translateX(-20px);
}
 100% {
 -moz-transform: translateX(0);
}
}
@keyframes cd-bounce-2-inverse {
 0% {
 opacity: 0;
 -webkit-transform: translateX(100px);
 -moz-transform: translateX(100px);
 -ms-transform: translateX(100px);
 -o-transform: translateX(100px);
 transform: translateX(100px);
}
 60% {
 opacity: 1;
 -webkit-transform: translateX(-20px);
 -moz-transform: translateX(-20px);
 -ms-transform: translateX(-20px);
 -o-transform: translateX(-20px);
 transform: translateX(-20px);
}
 100% {
 -webkit-transform: translateX(0);
 -moz-transform: translateX(0);
 -ms-transform: translateX(0);
 -o-transform: translateX(0);
 transform: translateX(0);
}
}
/* 17.3 Log-in */
.wrapper-page {
	width: 550px;
	margin: 7.5% auto;
}
.panel-pages .panel-body {
	padding: 30px;
}
.panel-pages {
	border-radius: 6px;
}
.panel-pages .panel-heading {
	padding: 40px 20px;
	border-radius: 6px 6px 0px 0px;
	-moz-border-radius: 6px 6px 0px 0px;
	-webkit-border-radius: 6px 6px 0px 0px;
	position: relative;
}
.panel-pages .panel-heading h3 {
	z-index: 999;
	position: relative;
}
/* 17.4 Lock-screen */
.user-thumb {
	margin-top: -44px;
	z-index: 999;
	position: relative;
}
.user-thumb img {
	margin: 0px auto;
	width: 88px;
	height: 88px;
}
/* 17.5 Error-pages */
.ex-page-content h1 {
	font-size: 98px;
	line-height: 150px;
	font-weight: 700;
	color: #252932;
	text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
}
/* 17.6 Coming soon */
.home-wrapper {
	margin: 10% 0px;
}
.home-text {
	font-family: 'work_sansregular';
}
.lj-countdown {
	color: #317eeb;
	margin-top: 40px;
	text-align: center;
}
.lj-countdown > * {
	text-align: center;
}
.lj-countdown div {
	display: inline-block;
}
.lj-countdown div span {
	width: 150px;
	display: block;
}
.lj-countdown div span:first-child {
	height: 48px;
	font-weight: 700;
	font-size: 3em;
	line-height: 48px;
}
.lj-countdown div span:last-child {
	height: 25px;
	font-size: 0.9em;
	line-height: 25px;
	color: #333;
}
/* 17.7 Maintenance */
.icon-main {
	font-size: 88px;
	margin-bottom: 50px;
}
.maintenance-page {
	margin: 10% 0%;
}
.mainten-box {
	margin-bottom: 30px;
}
.mainten-box .text-m-mode {
	width: 80%;
	margin: 0px auto;
}
/* 17.8 Conatct */
.media-main a.pull-left {
	width: 100px;
}
.media-main .info {
	overflow: hidden;
	color: #000;
}
.media-main .info h4 {
	padding-top: 10px;
	margin-bottom: 5px;
}
.social-links li a {
	background: #EFF0F4;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	display: inline-block;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	color: #7A7676;
}

/* ================== 18.RESPONSIVE ========================
=======================================================*/

@media only screen and (max-width: 6000px) and (min-width: 700px) {
.wrapper.right-bar-enabled .right-bar {
	right: 0;
	z-index: 99;
}
}

@media (min-width: 768px) and (max-width: 991px) {
body {
	overflow-x: hidden;
}
}

@media (max-width: 767px) {
body {
	overflow-x: hidden;
}
.mobile-sidebar {
	left: 0px;
}
.mobile-content {
	left: 250px;
	right: -250px;
}
.wrapper-page {
	width: 90%;
}
.navbar-nav .open .dropdown-menu {
	right: 0;
	left: auto;
	position: absolute;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
	background-color: #fff;
}
.todo-send {
	padding-left: 15px;
	margin-top: 10px;
}
.chat-inputbar {
	padding-left: 15px;
}
.chat-send {
	padding-left: 15px;
	margin-top: 10px;
	padding-right: 15px;
}
}

@media (max-width: 480px) {
.side-menu {
	z-index: 10 !important;
}
.button-menu-mobile {
	display: block;
}
.search-bar {
	display: none !important;
}
}

@media (max-width: 420px) {
.hide-phone {
	display: none !important;
}
}

/* Container-alt */
@media (min-width: 768px) {
.container-alt {
	width: 750px;
}
}

@media (min-width: 992px) {
.container-alt {
	width: 970px;
}
}

@media (min-width: 1200px) {
.container-alt {
	width: 1170px;
}
}
.navbar-form h3 {
	color: #7b96c4;
}
.navbar-default .navbar-nav>li>a {
	font-family: 'work_sansregular';
	font-size: 16px;
	font-weight: 500;
}
.dropdown-menu>li>a {
	display: block;
	padding: 0px 25px;
	clear: both;
	font-weight: 500;
	line-height: 2.42857143;
	font-size: 15px;
	white-space: nowrap;
	color: rgba(0,0,0,0.87);
	font-family: 'work_sansregular';
}
.border-left {
	border-left: 1px solid #eee;
}
.bluebg {
	background: #38DBD0;
}
.loginint input {
	background: rgba(255,255,255,0.5);
	height: 59px;
	border: none;
	font-size: 17px;
	font-weight: 500;
}
 .loginint::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
 color: #1D9890;
 opacity: 1; /* Firefox */
}
 .loginint:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #1D9890;
}
 .loginint::-ms-input-placeholder { /* Microsoft Edge */
 color: #1D9890;
}
.loginint .form-group {
	margin-bottom: 0px;
}
.loginint .btn {
	height: 61px;
	width: 100%;
	border-radius: 9px;
	background-color: #FFFFFF;
	color: #1D9890;
	border: none;
}
.bordertoprightleft {
	border-top-right-radius: 9px;
	border-top-left-radius: 9px;
	border-bottom: 1px solid #30C3B8!important;
}
.borderbottomrightleft {
	border-bottom-right-radius: 9px;
	border-bottom-left-radius: 9px;
}
.navbar {
	position: relative;
	min-height: 50px;
	margin-bottom: -1px!important;
	border: 1px solid transparent;
}
.navbar-brand {
	float: left;
	height: 25px;
	padding: 10px 15px 15px 0;
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
	background-color: #00A99F;
}
.tagn {
	padding-left: 17px;
	margin-top: 4px;
}
.chat-box input {
	height: 64px;
	width: 646px;
	border: 1px solid #ECECEC;
	background-color: #FFFFFF;
	color: #C2C2C2;
	font-family: 'work_sansregular';
	font-size: 16px;
}
.input-group-addon .fa {
/*font-size: 43px;*/
}
.send {
	height: 21px;
	width: 45px;
	color: #30DBD0;
	font-size: 18px;
	font-weight: 500;
	line-height: 21px;
	padding: 6px 24px!important;
	border: 1px solid #ECECEC;
}
.input-group-addon {
	padding: 6px 12px;
	font-size: 15px;
	font-weight: 400;
	line-height: 1;
	color: #66c6c0;
	text-align: center;
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 4px;
	border-left: 1px solid #ccc;
	border: 1px solid #ECECEC;
}
.font-size15 {
	font-size: 25px;
}
html, body {
	height: 100%;
	margin: 0px;
}
.chatheigh {
	height: 100%;
}
html, body, .row {
	height: 100%;
}
.row > div {
	height: 100%;
}
.filternew {
	text-align: right;
}
.inbox-item:hover {
	background-color: #EBFBFA;
}
.filing-cabinet {
	color: rgba(0,0,0,0.54);
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;
	text-transform: uppercase
}
.mini-stat-info span.name.list-item-one {
	color: rgba(0,0,0,0.87);
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	font-family: 'work_sansbold';
}
.mini-stat-info .secondary-text {
	color: rgba(0,0,0,0.54);
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	font-family: 'work_sansmedium';
}
.bordrradiosnone {
	border-radius: 0px!important;
	width: 50px;
}
.bordrradiosnone img {
	margin-left: 18px;
	padding-top: 11px;
}
.m-t-5 {
	margin-top: 5px;
}
.p-l-0 {
	padding-left: 0px;
	padding-right: 0px;
}
.searchnew {
	margin-top: 18px;
	height: 33px;
	width: 229px;
	border-radius: 16.5px;
	background-color: #4BE5DB;
}
.searchnew input {
	background: transparent;
	border: none;
	color: #fff;
}
.searchnew input:hover {
	background: transparent;
	border: none;
}
.searchnew input:focus {
	background: transparent;
	border: none;
}
.searchnew span {
	background: transparent;
	border: none;
}
 input.topsearch-bar::-webkit-input-placeholder {
 color:#fff;
}
input.topsearch-bar:-moz-placeholder {
 color:#fff;
}
input.topsearch-bar::-moz-placeholder {
 color:#fff;
}
input.topsearch-bar:-ms-input-placeholder {
 color:#fff;
}
.searchnew .fa {
	color: #fff!important;
}
.p-r-0 {
	padding-right: 0px;
}
.filteriput input {
	height: 43px;
	border: 1px solid #E2DFDF;
	border-radius: 5px;
	background-color: #FFFFFF;
	font-size: 15px;
}
.filteriput select {
	height: 43px;
	border: 1px solid #E2DFDF;
	border-radius: 5px;
	background-color: #FFFFFF;
	font-size: 15px;
}
.filteriput label {
	color: rgba(0,0,0,0.87);
	font-size: 15px;
	line-height: 20px;
	font-weight: 400;
}
.filteriput {
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 24px;
}
.button1 {
	height: 43px;
	color: #fff;
	width: 131px;
	border-radius: 5px;
	background-color: #30DBD0;
	border: 1px solid #30DBD0;
}
.button1:hover {
	height: 43px;
	color: #fff;
	width: 131px;
	border-radius: 5px;
	background-color: #30DBD0;
	border: 1px solid #30DBD0;
}
.button2 {
	height: 43px;
	color: #fff;
	width: 131px;
	border-radius: 5px;
	background-color: #C2C9C9;
}
.butttonred {
	height: 33px;
	border: 1px solid #E64129;
	background-color: #FFFFFF;
	color: #E64129;
	padding-top: 3px;
	font-size: 16px;
	line-height: 23px;
	font-weight: 400;
}
.btn-custom.btn-primary.butttonblue {
	height: 33px;
	border: 1px solid #38DBD0;
	border-radius: 16.5px;
	background-color: #FFFFFF;
	color: #38DBD0!important;
	padding-top: 3px;
	font-size: 16px;
	line-height: 23px;
	font-weight: 400;
}
.rwd-table {
	margin: 1em 0;
	width: 100%;
	border: 1px solid #D9D9D9;
}
.rwd-table td, th {
	border: 1px solid #E4E4E4;
	text-align: left;
	padding: 8px;
	text-align: center;
}
.padding30 {
	padding: 15px;
	padding-top: 20px
}
.rwd-table tr {
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	border: 1px solid #ddd;
}
.rwd-table th {
	display: none;
}
.rwd-table td {
	display: block;
}
.rwd-table td:first-child {
	padding-top: .5em;
}
.rwd-table td:last-child {
	padding-bottom: .5em;
}
.rwd-table td:before {
	content: attr(data-th) ": ";
	font-weight: bold;
	width: 6.5em;
	display: inline-block;
}

@media (min-width: 480px) {
.rwd-table td:before {
	display: none;
}
}
.rwd-table th, .rwd-table td {
	text-align: left;
}

@media (min-width: 480px) {
.rwd-table th, .rwd-table td {
	display: table-cell;
	padding: .25em .5em;
}
.rwd-table th:first-child, .rwd-table td:first-child {
	padding-left: 0;
}
.rwd-table th:last-child, .rwd-table td:last-child {
	padding-right: 0;
}
}
.rwd-table {
	background: #fff;
	color: #fff;
	border-radius: 0px;
	overflow: hidden;
	padding-top: 0px;
	margin-top: 0px!important;
	margin-bottom: 0px!important;
}
.rwd-table tr {
	border-color: #eee;
}
.rwd-table th, .rwd-table td {
	margin: .5em 1em;
	color: #606060;
	font-size: 18px;
	font-weight: 400;
	text-align: center;
}

@media (min-width: 480px) {
.rwd-table th, .rwd-table td {
	padding: 10px !important;
}
}
.rwd-table th, .rwd-table td:before {
	color: #dd5;
	color: #606060;
	font-size: 18px;
	font-weight: 400;
	background-color: #F1F1F1;
}
.tablebutton {
	height: 33px;
	width: 97px;
}
.btn-custom.btn-primary.butttonblue.tablebutton {
	height: 33px;
	border-radius: 54px;
	width: 97px;
}
/* Tabs panel */
.tabbable-panel {
	border: 1px solid #eee;
	padding: 0px;
	background: #fff;
}
/* Default mode */
.tabbable-line > .nav-tabs {
	border: none;
	margin: 0px;
}
.tabbable-line > .nav-tabs > li {
	margin-right: 2px;
}
.tabbable-line > .nav-tabs > li > a {
	font-size: 20px!important;
	font-weight: 400;
	text-transform: uppercase!important;
	border: 0;
	margin-right: 0;
	color: #C5C5C5!important;
	line-height: 73px;
}
.tabbable-line > .nav-tabs > li > a > i {
	color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open, .tabbable-line > .nav-tabs > li:hover {
	border-bottom: 0px solid #fbcdcf;
}
.tabbable-line > .nav-tabs > li.open > a, .tabbable-line > .nav-tabs > li:hover > a {
	border: 0;
	background: none !important;
	color: #333333;
}
.tabbable-line > .nav-tabs > li.open > a > i, .tabbable-line > .nav-tabs > li:hover > a > i {
	color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open .dropdown-menu, .tabbable-line > .nav-tabs > li:hover .dropdown-menu {
	margin-top: 0px;
}
.tabbable-line > .nav-tabs > li.active {
	color: #606060;
	position: relative;
}
.tabbable-line > .nav-tabs > li.active > a {
	color: #606060;
	font-size: 20px!important;
	font-weight: 400;
	border: 0;
	color: #606060!important;
}
.tabbable-line > .nav-tabs > li.active > a > i {
	color: #404040;
}
.tabbable-line > .tab-content {
	margin-top: 0px;
	background-color: #fff!important;
	border: 0;
	border-top: 1px solid #eee;
	padding: 15px 0;
	padding: 0px!important;
}
.portlet .tabbable-line > .tab-content {
	padding-bottom: 0;
}
/* Below tabs mode */

.tabbable-line.tabs-below > .nav-tabs > li {
	border-top: 4px solid transparent;
}
.tabbable-line.tabs-below > .nav-tabs > li > a {
	margin-top: 0;
}
.tabbable-line.tabs-below > .nav-tabs > li:hover {
	border-bottom: 0;
}
.tabbable-line.tabs-below > .nav-tabs > li.active {
	margin-bottom: -2px;
	border-bottom: 0;
}
.tabbable-line.tabs-below > .tab-content {
	margin-top: -10px;
	border-top: 0;
	border-bottom: 1px solid #eee;
	padding-bottom: 15px;
}
.administration-age {
	color: #626666;
	font-family: 'work_sansregular';
	font-size: 18px;
	font-weight: 400;
	line-height: 21px;
	padding-left: 26px;
	padding-top: 22px;
}
.border-right {
	border-right: 1px solid #eee!important;
}
.headbreadcrump {
	height: 71px;
	background-color: #FFFFFF;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}
.secondsearch input {
	height: 41px;
	width: 280px;
	border: 1px solid #E1E1E1;
	border-radius: 20.5px;
	background-color: #FFFFFF;
	font-size: 18px;
}
.secondsearch span {
	border-radius: 20.5px;
	background-color: #FFFFFF;
}
.margintright {
	margin-right: 30px;
	margin-top: 15px;
}
.margintright1 {
	margin-right: 0px;
	margin-top: 15px;
}
.modal-content label {
	color: rgba(0,0,0,0.87);
	font-size: 16px;
	font-weight: 400;
}
.modal-content input {
	height: 43px;
	border: 1px solid #E2DFDF;
	border-radius: 5px;
	background-color: #FFFFFF;
	;
}
.modal-content select {
	height: 43px;
	border: 1px solid #E2DFDF;
	border-radius: 5px;
	background-color: #FFFFFF;
	;
}
.modal-footer {
	padding: 15px;
	text-align: right;
	border-top: 1px solid #e5e5e5;
	padding-bottom: 20px!important;
	padding-right: 25px!important;
	padding-left: 25px!important;
}
.close {
	float: right;
	font-size: 21px;
	font-weight: 700;
	line-height: 1;
	color: #aaa;
	text-shadow: 0 1px 0 #fff;
	filter: alpha(opacity=20);
	opacity: 1;
}
.modal-title {
	margin: 0;
	line-height: 22px;
	font-weight: 500!important;
	font-size: 18px;
	text-transform: uppercase;
}
.changepassword {
	color: #38DBD0;
	font-family: 'work_sansregular';
	font-size: 16px;
	line-height: 20px;
}
.changepassword a {
	color: #38DBD0;
}

@media only screen and (min-device-width : 319px) and (max-device-width : 667px) {
html, body {
	height: inherit!important;
	margin: 0px;
}
.chatheigh {
	height: inherit!important;
}
html, body, .row {
	height: inherit!important;
}
.row > div {
	height: inherit!important;
}
.rwd-table th, .rwd-table td:before {
	color: #dd5;
	color: #606060;
	font-size: 18px;
	font-weight: 400;
	background-color: #fff!important;
}
.headbreadcrump {
	height: 71px;
	background-color: #FFFFFF;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
	padding-bottom: 20px;
}
.margintop65 {
	margin-top: 44px;
}
}
.tablehead {
	font-size: 20px!important;
	font-weight: 500;
	border: 0;
	padding-top: 24px;
	display: inline-block;
	text-transform: uppercase;
	color: #606060!important;
	padding-bottom: 24px;
	padding-left: 10px;
}
.butttonblueborder {
	height: 33px;
	border: 1px solid #4F55E2;
	border-radius: 16.5px;
	background-color: #FFFFFF;
	color: #4F55E2;
	padding-top: 3px;
	font-size: 16px;
	line-height: 23px;
	font-weight: 400;
}
.sandra-adams-copy-7 {
	min-height: 55px;
	color: rgba(0,0,0,0.87);
	font-family: 'work_sansregular';
	font-size: 16px;
	margin-top: 21px;
}
.headermiddle {
	height: 71px;
	background-color: #F1F1F1;
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
	padding-top: 23px;
	padding-left: 20px;
}
.p-h-0 {
	padding-left: 0px;
	padding-right: 0px;
}
.modal-body1 {
	padding-left: 50px;
	padding-right: 50px;
	padding-top: 50px;
}
.modal-body1 input {
	height: 43px;
	border: 1px solid #E2DFDF;
	border-radius: 5px;
	background-color: #FFFFFF;
}
.modal-body1 label {
	color: rgba(0,0,0,0.87);
	font-size: 16px;
	font-weight: 400;
}
.btnbig {
	padding: 10px;
	padding-right: 20px;
	padding-left: 20px;
	font-size: 16px;
}
.modal-content select {
	height: 43px;
	border: 1px solid #E2DFDF;
	border-radius: 5px;
	background-color: #FFFFFF;
}
.pop-btn {
	width: 113px;
	height: 40px;
}
.info-tag {
	font-size: 18px;
	margin-bottom: 40px;
	font-weight: 500;
	font-size: #252525;
	text-align: center;
}
/* View Customer Css */
.blue-bg {
	background-color: #fff;
	height: 81px;
	padding: 15px 20px;
	border-bottom: 1px solid #E8E8E8;
}
.blue-bg:hover {
	background-color: #EBFBFA;
	height: 81px;
	padding: 15px 20px;
}
.blue-bg .property {
	color: rgba(0,0,0,0.87);
	font-family: 'work_sansregular';
	font-size: 18px;
	font-weight: 500;
	line-height: 21px;
}
.blue-bg .property-address {
	color: rgba(116,116,116,0.87);
	font-family: 'work_sansregular';
	font-size: 16px;
	line-height: 20px;
}
.blue-bg:hover .property-address {
	color: rgba(0,0,0,0.87);
	font-family: 'work_sansregular';
	font-size: 16px;
	line-height: 20px;
}
.middle-container {
	padding: 15px 30px;
	clear: both;
	overflow: hidden;
	background-color: #fff;
	border-bottom:1px solid #eee;
}
.middle-container .property-name {
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
}
.middle-container .property-address {
	color: rgba(0,0,0,0.87);
	font-size: 16px;
	line-height: 20px;
}
.property-list {
}
.property-list .border-btm {
	border-bottom: 1px solid #EEEEEE;
	padding-top: 10px;
}
.property-list .border-btm p {
	padding-left: 10px;
	padding-right: 10px;
}
.property-list .property-list-name {
	color: rgba(0,0,0,0.87);
	font-size: 16px;
	letter-spacing: -0.27px;
	line-height: 20px;
}
.property-list .property-list-name .time {
	color: rgba(0,0,0,0.54);
	font-size: 14px;
	letter-spacing: -0.24px;
	line-height: 20px;
	float: right;
}
.property-list .property-description {
	color: rgba(0,0,0,0.54);
	font-size: 16px;
	line-height: 20px;
}
.property-list .conversion {
	color: rgba(0,0,0,0.54);
	font-size: 14px;
	line-height: 23px;
}
.property-list table.rwd-table tr td {
	color: rgba(131,131,131,0.87);
	font-size: 15px;
	line-height: 18px;
	font-weight: 400;
}
.property-list .conversion span {
	background: rgba(0,0,0,0.54);
	border-radius: 100%;
	width: 4px;
	height: 4px;
	display: inline-block;
}
a.view-all {
	width: 168px;
	color: #A0A0A0;
	font-size: 13px;
	line-height: 15px;
}
.fl-right {
	float: right;
}
.up-arrow {
	color: #aaa;
	font-size: 15px;
}
.down-arrow {
	color: #555;
	font-size: 15px;
}
.p-l-0 {
	padding-left: 0
}
.p-r-0 {
	padding-left: 0
}
.p-b-15 {
	padding-bottom: 15px;
}
.m-t-20 {
	margin-top: 15px;
}
.m-t-10 {
	margin-top: 10px;
}
.m-t-25 {
	margin-top: 25px;
}
.p-all {
	padding: 15px;
	background: #fff;
	padding-bottom: 22px;
	margin-left: 15px;
}
.font-14 {
	font-size: 14px !important;
}
.font-16 {
	font-size: 16px !important;
}
.clr-gery {
	color: rgba(0,0,0,0.54) !important;
	font-family: 'work_sansregular' !important;
	line-height: 20px !important;
}

.selectProperty{
	background-color:white!important;
	cursor: pointer;
	border-left: 4px solid #38DBD0;
}

.selectPendingProperty{
	background-color:white!important;
	cursor: pointer;
	border-left: 9px solid #38DBD0;
}

.payment-rim-btn{
margin-top: 30px;
height: 36px;
width: 100%;
border-radius: 2px;
background-color: var(--button-secondary-color);
box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
color: #FFFFFF;
font-weight: 500;
font-size: 14px;
line-height: 17px;
text-align: center;
}
.chat-pay-btn{
	margin-top: 30px;
height: 36px;
width: 50%;
border-radius: 2px;
background-color: #f9b64e;
box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
color: #FFFFFF;
font-weight: 500;
font-size: 14px;
line-height: 17px;
text-align: center;
}
.chat-sugg-box{
position: absolute;
bottom: 64px;
width: 95%;
margin-bottom: 80px;
}
.auth0-lock-name {
	display: none !important;
  }
  
  .auth0-lock-header-bg {
	background: #30bfb6 !important;
  }
  
  .auth0-lock-close-button {
	display: none !important;
  }
  
  .auth0-lock-pane-separator + div {
	  display: none;
  }

  .auth0-lock-submit {
	  display: none !important;
  }
/*// background-color:#38DBD0!important;*/
