@charset "utf-8";

/* CSS Document */


@media(max-width:1024px) {
.nav.nav-tabs > li > a {
  line-height: 20px;
}
.inbox-widget .inbox-item .inbox-item-text.active, .inbox-widget .inbox-item .inbox-item-author.active {
  font-size: 14px !important;
  font-weight: 500 !important;
}
.inbox-widget .inbox-item .inbox-item-date.active, .inbox-widget .inbox-item .inbox-item-date {
  right: 0;
  font-weight: 500 !important;
  font-size: 13px;
  line-height: 23px;
}
.inbox-widget .inbox-item .inbox-item-author, .inbox-widget .inbox-item .inbox-item-text {
  font-size: 14px;
}
}

@media(max-width:960px) {
}

@media(max-width:800px) {
}

@media(max-width:768px) {
.nav > li > a {
  padding: 0px 15px;
  line-height: 60px;
}
.navbar-default .navbar-nav > li > a {
  font-size: 15px;
}
.searchnew {
  margin-top: 12px;
  width: 200px;
}
.dropdown-menu > li > a {
  font-size: 15px;
}
.tabbable-panel .m-t-20 {
    margin-top: 20px;
}
}

@media(max-width:767px) {
.fl-right {
  float: left;
  width: 100%;
}
.nav.navbar-nav {
  padding-bottom: 15px;
}
.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}
.nav > li > a {
  padding: 0px 15px;
  line-height: 50px;
}
.middle-container {
  height: auto;
}
.middle-container .property-address {
  margin-bottom: 10px;
}
.middle-container .text-right {
  text-align: left
}
.searchnew {
  margin-top: 5px;
}
.dropdown-menu > li > a {
  font-size: 15px;
}
.filternew {
  padding-right: 10px;
}
.inbox-widget .inbox-item .inbox-item-text.active {
  font-weight: 500;
  padding-left: 5px;
}
.inbox-widget .inbox-item .inbox-item-text {
  padding-left: 5px;
}
.mini-stat {
  margin-bottom: 0;
}
.p-l-0, .p-h-0 {
  padding-left: 15px;
  padding-right: 15px;
}
.inbox-widget .inbox-item .inbox-item-img {
  margin-left: -15px;
}
.portlet {
  padding: 0;
}
.tagn {
  padding-left: 5px;
  margin-top: 5px;
}
.padding30 { padding:0;}
.rwd-table th, .rwd-table td {
    margin: 10px;text-align: left;
}
.margintright {
    margin-right: 15px;
    
    margin-left: 15px;
}
.tabbable-panel .text-right{ text-align:left}
.tabbable-panel .m-t-20 {
    margin-top: 0;
}
}
