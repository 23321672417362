:root 
{
    // color
    --primary-color: #00a99f;
    --secondary-color: #f5f5f5;
    --primary-white: #FFFFFF;
    --secondary-white: #fafafa;
    --primary-black: #000000;
    --primary-green: #306465;
    --primary-purple: #6B696C;
    --primary-dark-gray: #212121;
    --header-dark-gray: #202020;
    --primary-dark-cyan: #21a9a0;
    --secondary-dark-gray: #747474;
    --primary-brown: #333;
    --error-color: #e01a00;
    --danger-color: #E1024F;
    --warning-color: #FFB441;
    --active-color: #38dbd0;
    --button-color: #00aa9f;
    --button-secondary-color: #03a59c;
    --light-color: #F4F8F9;
    --placeholder-color: #9fa1a2;
    --border-color-base: #eaeaea;
    --border-color-light: #EDEDED;
    --border-color-green: #30bfb5;
    --border-color-danger: #FF0000;
    --radio-color: #009688;
    --tab-color: #5c5c5c;
    --not-available-color: #9a9a9a;
    --select-option-color: #242424;
    --search-field-bg-color: #f6f6f6;
    --light-gray-color: #646464;
    --select-box-hover-color: #dadada;
    --background-audio-call-color: #efefef;
    --manager-color: #4B7DE0;
    --border-search-input: #0BADA3;
    --highlight-search-text: #00AA9F;
    --background-file-denied: #FFF4F4;
    --date-pdf-file: #6b696c;
    --arrow-dropdown: #666666;
    --logo-part-color1: #ffbf41;
    --logo-part-color2: #38dbd0;
    --logo-part-color3: #4a7ce0;
    --email-header-text:#061437;

    // base border 
    --base-border: 1px solid rgba(0, 0, 0, 0.12);

    // font-size
    --base-font-size: 14px;
}
